import type { AppProps } from "next/app";
import App from "next/app";
import Head from "next/head";
import MerakiSDK from "@urbanpiper-engineering/meraki-sdk";
import { Channel } from "meraki-sdk/dist/esm/common/enums/channel.enum";

import { Source_Sans_Pro } from "@next/font/google";
import { appWithTranslation } from "next-i18next";

import ThemeProvider from "@urbanpiper-engineering/meraki-components/dist/provider";
import Layout from "../components/layout";

import nextI18nConfig from "../next-i18next.config";
import configSelector from "../external/configSelector";

import ErrorBoundary from "../utils/error";

import "./global.scss";
import { StoreState } from "@urbanpiper-engineering/meraki-sdk/dist/esm/stores/states/store.state";
import pageSelector from "../external/pageSelector";
import { useEffect, useState } from "react";
import { getMixPanelIds } from "../utils/getMixPanelIds";

const fonts = Source_Sans_Pro({
  variable: "--font-family",
  weight: ["400", "600", "700"],
  subsets: ["latin"],
  display: "swap",
  preload: true,
});

MedusaApp.getInitialProps = async (appContext: any) => {
  const appProps = await App.getInitialProps(appContext);
  await configSelector.updateConfigFromJSON();
  await MerakiSDK.init(configSelector.getSDKConfig());

  const theme = {
    fontFamily: fonts.style.fontFamily,
    currency: "",
    colors: {
      primaryColor: configSelector.getPrimaryColor(),
      secondaryColor: configSelector.getSecondaryColor(),
      tertiaryColor: configSelector.getTertiaryColor(),
      successColor: configSelector.getSuccessColor(),
      failureColor: configSelector.getFailureColor(),
      primaryTextColor: configSelector.getPrimaryTextColor(),
      secondaryTextColor: configSelector.getSecondaryTextColor(),
      headerBackgroundColor: "#ffffff",
    },
    isDarkTheme: false,
  };

  theme.currency = MerakiSDK.getBusinessInfo().currency;
  const supportedLanguages = MerakiSDK.getBusinessInfo().supported_languages;
  appProps.pageProps.supportedLanguages = supportedLanguages;

  return {
    ...appProps,
    theme,
  };
};

function MedusaApp({ Component, pageProps, theme }: AppProps & { theme: any }) {
  const [loadingConfig, setLoadingConfig] = useState(true);
  const onConfigUpdated = async () => {
    const config = await fetch(`/config/root.config.json`, {
      method: "GET",
      headers: { "content-type": "application/json" },
    });
    const configJSON = await config.json();
    configSelector.updateConfig(configJSON);
    pageSelector.updateConfig(configJSON);
  };

  const freshChatService = async () => {
    const freshChat = configSelector.getFreshChat();

    if (freshChat.enable && freshChat.token.length) {
      const freshChatService = await MerakiSDK.getFreshChat();
      freshChatService.init({
        token: freshChat.token,
        backgroundColor: configSelector.getPrimaryColor(),
      });
    }
  };

  useEffect(() => {
    (async () => {
      await onConfigUpdated();
      setLoadingConfig(false);
      await MerakiSDK.init(configSelector.getSDKConfig());

      theme.isStoreClosed = MerakiSDK.getStoreState().getIsStoreClosed() || false;

      const analyticsConfig = {
        biz_id: configSelector.getBizId(),
        biz_name: configSelector.getBizName(),
        currency: theme?.currency,
      };
      const analyticsInitOptions = {
        mixpanelId: [`093dd94ada4364eff06e76b5eb63ed0a`],
        googleAnalyticsId: "",
        googleTagManagerId: "",
        appsflyerId: "",
        config: analyticsConfig,
      };

      if (
        configSelector.getMixPanel()?.enable &&
        configSelector.getMixPanel()?.tokens?.length > 0
      ) {
        analyticsInitOptions.mixpanelId = getMixPanelIds(
          analyticsInitOptions.mixpanelId,
          configSelector.getMixPanel().tokens
        );
      }

      if (configSelector.getGTM()?.enable) {
        analyticsInitOptions.googleTagManagerId = configSelector.getGTM().id;
      }
      if (configSelector.getGA()?.enable) {
        analyticsInitOptions.googleAnalyticsId = configSelector.getGA().id;
      }

      MerakiSDK.analyticsInit(analyticsInitOptions);
      freshChatService();
    })();
  }, []);

  if (loadingConfig) {
    return <></>;
  }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="icon"
          type="image/x-icon"
          href={configSelector.getFavicon()}
        />
      </Head>
      <ThemeProvider theme={theme}>
        <Layout {...pageProps} className={fonts.className}>
          <ErrorBoundary>
            <Component {...pageProps} />
          </ErrorBoundary>
        </Layout>
      </ThemeProvider>
    </>
  );
}

export default appWithTranslation(MedusaApp, nextI18nConfig);
